<template>
<div v-if="show" class="fixed z-[100] inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-if="show" class="fixed inset-0 bg-white opacity-50 transition-opacity pt-14" aria-hidden="true"></div>
    </transition>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to-class="opacity-100 translate-y-0 sm:scale-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100 translate-y-0 sm:scale-100"
      leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
      <div v-if="show" class="inline-block rounded-lg p-7 md:p-7 overflow-hidden transform transition-all sm:my-8 max-w-5xl w-full sm:p-8">
        <div class="flex items-center justify-center">
          <SpinnersChasingDots />
        </div>
      </div>
    </transition>
  </div>
</div>
</template>

<script>

export default {
  computed: {
    show() {
      const store = useGlobalStore();
      return store.$state.showGlobalSpinner;
    }
  }
}
</script>

<style scoped>
.player {
  --plyr-color-main: #2563eb;
}

.modal-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0 12px 30px 0;
}
</style>